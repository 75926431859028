<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF LOANS</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-4">
        <v-flex xs12 md4>
          <v-select
            class="mx-2"
            v-model="status"
            :items="['Unpaid','Paid']"
            label="Status"
            required
            :rules="rules.combobox_rule"
            dense
            outlined
            @change="search_data"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="data"
                    disable-pagination
                    hide-default-footer
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{
              data
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id) + 1
              }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.member.company_name }}
            </td>
            <td>
              {{ item.collateral }}
            </td>
            <td>
              {{ formatPrice(item.loan_amount) }}
            </td>
            <td>
              {{ item.terms+' months' }}
            </td>
            <td>
              {{ item.interest+' %' }}
            </td>
            <td>
              {{ formatPrice(item.total_interest )}}
            </td>
            <td>
              {{ formatPrice(item.total_loan) }}
            </td>
            <td>
              {{ formatPrice(get_balance(item)) }}
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                @click="print_soa(item)"
              >
                {{icons.mdiAccountClock}}
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiPlusBox
    , mdiPencil
    , mdiAccountSearchOutline
    , mdiAccountClock
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {P} from 'caniuse-lite/data/agents'

  const initialState = () => {
    return {
      search: '',
      status: '',
      type_of_loan: '',
      data: [],
      headers: [
        {text: 'No.', value: 'id', sortable: false},
        {text: 'Date', value: 'date', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Collateral', value: 'date', sortable: false},
        {text: 'Loan Amount', value: 'loan_amount', sortable: false},
        {text: 'Terms', value: 'terms', sortable: false},
        {text: 'Interest (%)', value: 'interest', sortable: false},
        {text: 'Interest', value: 'total_interest', sortable: false},
        {text: 'Total Loan', value: 'total_loan', sortable: false},
        {text: 'Loan Balance', value: 'total_loan', sortable: false},
        {text: 'SOA', value: 'total_loan', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPlusBox,
          mdiPencil,
          mdiAccountSearchOutline,
          mdiAccountClock,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loan', ['list_of_loans']),
      search_data() {
        this.list_of_loans({
          status: this.status,
        })
          .then(response => {
            this.data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      get_balance(value) {
        var paid = 0;
        value.payment_paid.forEach(function (item) {
          paid += (parseFloat(item.amount) + parseFloat(item.interest))
        })
        return parseFloat(value.total_loan) - parseFloat(paid)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_soa(data) {
        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Due Date', alignment: 'left', style: 'label'},
            {text: 'OR date', alignment: 'left', style: 'label'},
            {text: 'OR #', alignment: 'left', style: 'label'},
            {text: 'Premium', alignment: 'left', style: 'label'},
            {text: 'Penalty', alignment: 'left', style: 'label'},
            {text: 'Balance', alignment: 'left', style: 'label'},
            {text: 'Status', alignment: 'left', style: 'label'},
          ]
        )
        var total_bal = data.total_loan
        accounts_array.push(
          [
            {text: data.date, alignment: 'left'},
            {text: '', alignment: 'left'},
            {text: '', alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(total_bal), alignment: 'left'},
            {text: '', alignment: 'left'},
          ]
        )

        data.payment.forEach(function (item) {
          var payment = parseFloat(item.amount) + parseFloat(item.interest)
          total_bal -= payment
          if (total_bal < 1) {
            total_bal = 0
          }
          accounts_array.push(
            [
              {text: item.due_date, alignment: 'left'},
              {text: item.or_date, alignment: 'left'},
              {text: item.or_no, alignment: 'left'},
              {
                text: (payment / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left'
              },
              {
                text: (item.penalty / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left'
              },
              {
                text: (total_bal / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left'
              },
              {text: (item.deposit_slip_id === null ? 'UNPAID' : 'PAID'), alignment: 'left'},
            ]
          )
        })

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: this.company_logo, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'NATIONAL MORTUARY ASSOCIATION OF THE PHILIPPINES INC.',
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LOAN STATEMENT OF ACCOUNT', style: 'title'},
            '================================================================================',
            ' ',
            {
              columns: [
                {
                  text: 'Date Accomplished: ' + data.date,
                  style: 'main_info'
                },
                {
                  text: 'ID#: ' + data.id,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'MEMBERS PERSONAL INFORMATION',
            ' ',
            {
              columns: [
                {
                  text: 'Applicant Name: ' + data.member.company_name,
                  style: 'main_info'
                },
                {
                  text: 'Contact #: ' + data.member.contact_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Present Address: ' + data.member.address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'CO MAKER INFORMATION',
            ' ',
            {
              columns: [
                {text: 'CO - MAKER NAME', style: 'main_info'},
              ]
            },
            {
              columns: [
                {
                  text: data.co_maker,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'LOAN DATA',
            ' ',
            {
              columns: [
                {text: data.terms + ' month/s', style: 'main_info'},
                {text: '|', style: 'main_info'},
                {
                  text: this.formatPrice(data.loan_amount) + '     +',
                  style: 'main_info'
                },
                {
                  text: this.formatPrice(data.total_interest) + '     +',
                  style: 'main_info'
                },
                {text: this.formatPrice(data.total_loan), style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: 'Terms', style: 'sub_info'},
                {text: '|', style: 'main_info'},
                {text: 'Loan Amount', style: 'sub_info'},
                {text: 'Interest', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [60, 90, 90, 60, 60, 60, 60],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with GGC System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            subheader2: {
              fontSize: 8,
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
    },
  }
</script>
